<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    width="550px"
    @cancel="cancel"
  >
    <template v-slot:bottom>
      <el-button @click="cancel">{{ $t('base.cancel') }}</el-button>
      <el-button type="primary" @click="confirm" v-if="showResumeReg">
        <!-- lang:确定 -->
        {{ $t('functionEnum.REGISTER_COMPANY_GOON') }}
      </el-button>
    </template>
  </dialog-form-list>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      currentCompany: null,
      config: [
        // lang:企业名称
        {
          label: this.$t('registerManage.companyName'),
          prop: 'name',
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 36,
            showWordLimit: true,
          },
        },
        // lang:统一社会信用代码
        {
          label: this.$t('registerManage.companycode'),
          prop: 'creditCodeForInvoice',
          rules: {
            noNull: true,
          },
          tagProps: {
             maxlength: 18,
          },
        },
        // lang:所属行业
        {
          tag: 'el-cascader',
          label: this.$t('registerManage.industry'),
          prop: 'industry',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:企业业务
        {
          label: this.$t('registerManage.business'),
          prop: 'businessScope',
          rules: {
            noNull: true,
          },
        },
        // lang:联系地址
        {
          tag: 'el-cascader',
          label: this.$t('registerManage.address'),
          prop: 'address',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:详细地址
        {
          label: this.$t('registerManage.addressDetail'),
          prop: 'addressDetail',
          rules: {
            noNull: true,
          },
        },
        // lang:联系人
        {
          label: this.$t('registerManage.contacts'),
          prop: 'contacts',
          rules: {
            noNull: true,
          },
        },
        // lang:联系方式
        {
          label: this.$t('registerManage.information'),
          prop: 'phone',
          rules: {
            noNull: true,
            type: 'mobileOrPhone',
          },
          tagProps: {
             maxlength: 11,
          },
        },
        // lang:邮箱
        {
          label: this.$t('registerManage.email'),
          prop: 'email',
          rules: {
            noNull: true,
            type: 'email',
          },
        },
        // lang:开票类型
        {
          label: this.$t('registerManage.invoiceType'),
          prop: 'invoiceType',
          rules: {
            noNull: true,
          },
        },
        // lang:开票企业开户行
        {
          label: this.$t('registerManage.invoicingname'),
          prop: 'invoicingname',
          rules: {
            noNull: true,
          },
        },
        // lang:企业开户账号
        {
          label: this.$t('registerManage.account'),
          prop: 'account',
          rules: {
            noNull: true,
          },
        },
        // lang:营业执照盖章上传
        {
          tag:'show-image',
          label: this.$t('registerManage.licenseUpload'),
          prop: 'businessLicenseFileImg',
          rules: {
            noNull: true,
          },
        },
        // lang:授权书上传
        {
          label: this.$t('registerManage.authorizationUpload'),
          prop: 'authorizationFileName',
          rules: {
            noNull: true,
          },
        },
        // lang:跟踪备注
        {
          label: this.$t('registerManage.remarks'),
          prop: 'notes',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 3,
          },
        },
      ],
    }
  },
  computed: {
    showResumeReg() {
      return this.$page.showBtn('REGISTER_COMPANY_ALLOT_SP') && this.tableRow.newRegister === 'YES'
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        const language = this.$util.getLocalStorage('lang')
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          console.log(this.formData, this.tableRow)
          if(this.tableRow.invoice.invoiceType === 'GENERAL'){
            this.formData.invoiceType = this.$t('registerManage.GENERAL')
          } else {
            this.formData.invoiceType = this.$t('registerManage.VAT')
          }
          this.formData.invoicingname = this.tableRow.invoice.openAccountBankNameForInvoice
          this.formData.account = this.tableRow.invoice.openAccountBankAccountForInvoice
          const { businessLicenseFile, authorizationFile } = this.tableRow
          // 营业执照

          setTimeout(() => {
            this.formData.businessLicenseFileImg = businessLicenseFile.fileHost + '/' + businessLicenseFile.objectName
            // 授权书
            this.formData.authorizationFileImg = authorizationFile.fileHost + '/' + authorizationFile.objectName
            this.formData.authorizationFileName = authorizationFile.originalName
          }, 500)
          // 语言处理
          this.dictFindTree(
            'COMPANY_INDUSTRY',
            this.tableRow.industry ? this.tableRow.industry.language : language
          )
          this.dictFindTree(
            'ADDRESS',
            this.tableRow.address ? this.tableRow.address.language : language
          )
          // 行业处理
          if (!this.tableRow.industry) {
            this.formData.industry = ['OTHER']
          } else {
            this.formData.industry = this.$util.getTreeIds(
              this.tableRow.industry
            )
          }
          // 地址处理
          this.formData.address = this.$util.getTreeIds(this.tableRow.address)
          this.formData.invoice = this.$util.getTreeIds(this.tableRow.invoice)
        } else {
          this.dictFindTree('COMPANY_INDUSTRY', language)
          this.dictFindTree('ADDRESS', language)
        }
      })
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        if (dictType === 'COMPANY_INDUSTRY') {
          data.push({
            id: 'OTHER',
            value: '其他',
          })
        }
        if (dictType === 'COMPANY_INDUSTRY') {
          this.config[2].tagProps.options = data || []
        } else {
          this.config[4].tagProps.options = data || []
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      if(this.tableRow.serviceProvider === null) {
        this.$element.showMsg(
            this.$t('registerManage.noRegister'),'error' )
        return
      }
      // 处理行业
      const industry = formData.industry.pop()
      if (industry === 'OTHER') formData.industry = null
      else formData.industry = { id: industry }
      // 处理地址
      formData.address = { id: formData.address.pop() }
      this.editMethod(formData)
    },

    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      console.log(tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        //url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyUpdate,
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.registerCompanyGoon,
        data: {
          id: tableRow.id
        },
      }).then(() => {
        // lang:修改成功
         this.$element.showMsg(
          this.$t('registerManage.setRemarksSuccess'),'success' )
        this.$emit('closePage', 'notes')
      })
    },
  },
}
</script>
